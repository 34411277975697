@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

@font-face {
  font-family: HKGrotesk;
  src: url(asset/fonts/hk-grotesk/HKGrotesk-Medium.otf);
}

html {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.displaydate {
  box-sizing: border-box;
  max-width: 176px;
  height: 60px;
  border: 1px solid #D3DCE6;
  border-radius: 16px;
  margin-left: 8px;
  text-align: center;
  padding: 15px;
}

.moveToNext {
  padding: 0 !important;
  position: absolute;
  width: calc(544px);
  bottom: 36px;

}

.progressbar {
  margin-bottom: 24px;
  margin-top: 20px;
}

@media only screen and (max-width: 800px) {
  .rdrMonths {
    display: block !important;
  }

  .travelsmentlogo {
    display: none;
  }


  .progressbar {
    margin-bottom: 24px;
    margin-top: -20px !important;
  }

  .h3Header {
    margin-bottom: 8px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */


    /* black */

    color: #273443;
  }

  .rdrDateDisplayWrapper {
    background-color: rgb(239, 242, 247);
    display: none;
  }
  .css-1sy1a1q-MuiTypography-root-PrivatePickersMonth-root{
    max-width: 93px !important;
    background-color: #F7F8FB !important;
    margin: 4px !important;
    height: 80px !important;
    border-radius: 8px !important;
  }
  .css-15fehp8-MuiTypography-root-PrivatePickersMonth-root.Mui-selected{
    max-width: 93px !important;
    margin: 4px !important;
    height: 80px !important;
    border-radius: 8px !important;
  }
  
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 157, 255, 0.3);
    background-color: #F5F5F5;
  }
  
  ::-webkit-scrollbar {
    width: 3px;
    background-color: #F5F5F5;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgb(0, 157, 255);
    border: 1px solid rgb(0, 157, 255);
  }
  
  .MainQuestion {
    padding: 0px !important;
    /* background-color: red; */
  }

  .tripLocation {
    margin-bottom: 12px !important;
  }

  .textBoxArea {
    width: 100% !important;
  }
.cruiseDestinationTextBox{
  width: 100% !important;
  
  
}
  .moveToNext {
    padding: 0 !important;
    position: absolute;
    width: calc(544px);
    bottom: 36px;
    max-width: 92%;
    /* margin-top: calc(100vh - 660px); */
  }

  .MainQueSet {
    /* max-height: calc(100vh - 360px) !important;
    min-height: calc(100vh - 360px) !important; */
    /* background-color: red; */
    /* overflow-y: auto; */
  }
}

.queDisplay {
  height: calc(100vh - 210px);
  overflow-y: auto;
}

.date {

  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  display: flex;
  color: #273443;
  margin-top: 4px;


}

.week {
  display: flex;
  flex-direction: column;
  width: 56px;
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  align-items: self-start;
  font-size: 12px;
  line-height: 140%;
  color: #8492A5;
  margin-top: -3px;
  margin-left: 14px;
}

.year {

  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #273443;
}
.yearNew{
font-family: HKGrotesk;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 140%;
color: #273443;
display: flex;
justify-content: flex-start;
gap: 5px;
cursor: pointer;



}
.cruiseDestinationTextBox{
  width: 250px;
  margin-top: 20px;
}

/* .css-n3vk4j-MuiMonthPicker-root{
  width: 380px !important;
  margin: 4px !important;
}
.css-1sy1a1q-MuiTypography-root-PrivatePickersMonth-root {
  height: 80px !important;
  border-radius: 8px !important;
  background-color: #F7F8FB !important;
  margin-top: 8px !important;
  margin-left: 4px !important;
  max-width: 104px !important;
}
.css-15fehp8-MuiTypography-root-PrivatePickersMonth-root{
  height: 80px !important;
  border-radius: 8px !important;
  margin-top: 8px !important;
  margin-left: 4px !important;
  max-width: 104px !important;
} */

.show {
  display: block;
  padding: 0px;
  padding-left: 20px;
  position: absolute !important;
  bottom: 30px !important;
  width: 100vw !important;
  left: 0px !important;
  height: 24px !important;
}

#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

textarea:focus,
input:focus {
  outline: none;
}

.options {
  color: #273443;
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  padding: 8px;
  background-color: #F7F8FB;
  /* border: 1px solid rgb(0, 145, 235); */
  margin-right: 10px;
  margin-bottom: 16px;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  min-width: 95px;
  text-align: center;
}

.options:hover {
  background-color: rgb(235, 247, 255);
}

.selected:hover {
  background-color: rgb(0, 157, 255) !important;
}
.checkbox{
  display: flex;
  flex-direction: column;


}
.list{
  display: flex;
  margin-top: 22.4px;
  margin-left: 10px;
}


/* .listCheckbox{
box-sizing: border-box;
border: 1px solid #E0E6ED;
border-radius: 4px;

background: #FFFFFF;

} */
.MuiSvgIcon-root{
  fill:  #0094FF !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  background: #FFFFFF !important;
  width: 20px !important;
height: 20px !important;
}

.listLabel{
font-style: normal;
font-weight: 500;
font-size: 16px;
 max-width:252px;
Height:22px;
margin-left: 10px;
color: #273443;
}

.animationFadeIn1 {
  -webkit-animation: 0.5s ease 0s normal 1 fadein1;
  animation: 0.5s ease 0s normal 1 fadein1;
}

.animationFadeIn2 {
  -webkit-animation: 1s ease 0s normal 1 fadein2;
  animation: 1s ease 0s normal 1 fadein2;
}

@keyframes fadein1 {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein1 {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein2 {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein2 {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.MainQuestion {
  /* border: 1px solid #ddd; */
  border-radius: 20px;
  border-top-left-radius: 3px;
  padding: 0px 20px;
  width: 100%;
  display: inline-block;
}

h3,
h4 {
  line-height: 0px;
}

h4 {
  font-size: 30px !important;
}

.age {
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #8492A5;
}

.sumvalue {
  background: #F7F8FB;
  border-radius: 8px;
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #0094FF;
  cursor: pointer;
}

.h4update {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 140% !important;
  color: #273443;
  margin-bottom: -16px;
}

.perfect {
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #8492A5;
  margin-bottom: 16px;
}

.center {
  width: 100%;
  margin: auto;
  padding: 16px;
}

.yourAssistant {
  display: flex;
  margin-top: 20px;
}

.rightSide {
  padding: 8px 0px;
}

.image img {
  height: 48px;
}

.tenantName {
  font-size: 16px;
}

.assistant {
  font-size: 12px;
  padding: 4px 0px;
  color: #666;
}



.NextButton {
  color: #FFFFFF;
  cursor: pointer;
  padding: 14px 28px;
  border-radius: 7px;
  float: right;
  background-color: rgb(0, 157, 255);
  margin-right: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  text-align: center;

  /* white */

  color: #FFFFFF;
}

.overflowy {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 10px;
}



.infoMsg {
  padding: 10px;
  border: 1px solid rgb(255, 117, 67);
  background-color: rgb(255, 214, 199);
  border-radius: 6px;
  margin-bottom: 8px;
  margin-top: 20px;
  color: black;
}

.selectButton {
  color: white;
  cursor: pointer;
  padding: 14px 28px;
  border-radius: 7px;
  font-size: 16px;

  background-color: rgb(0, 157, 255);
  margin-right: 8px;
}

.notAllowed {
  cursor: wait;
  color: white;
  padding: 14px 28px;
  border-radius: 7px;
  font-size: 16px;
  margin-right: 8px;
  background-color: rgb(72, 183, 251);
}

.actCountadults {
  width: 76px;
  height: 48px;
  padding: 13px;
  margin-right: 12px;
  margin-left: 12px;
  background: #F7F8FB;
  border-radius: 16px;
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  margin-top: -11px;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #273443;
}

.thankscenter {
  text-align: center;
  /* margin-top: 80px; */
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 200px);
}

.thanks {
  font-size: 40px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 28px */

  text-align: center;

  /* black */

  color: #273443;
}

.thanksinfo {
  max-width: 272px;
  margin-top: 16px;
  align-self: center;
  height: 44px;
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #273443;
}

.thanksImg {
  height: 100px;
}

.BackButton {
  /* color: rgb(0, 157, 255); */
  cursor: pointer;
  padding: 10px 20px;
  text-decoration: underline;
  border-radius: 7px;
  float: left;
  margin-right: 8px;
  /* margin-top: -16px; */
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #0094FF;
}

.selectedAns {
  padding: 8px 16px;
}

.selected {
  color: white;
  background-color: rgb(0, 157, 255);
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
}

.textBoxArea {
  padding: 10px 0px;
  width: 320px;
}

.textMBoxArea {
  padding: 10px 0px;
  width: 100%;
}

.passeng {
  display: flex;
  padding: 8px;
  padding-left: 0px;
}

.actButton {
  padding: 2px 26px;
  width: 48px;
  height: 48px;
  text-align: center;
  /* border: 1px solid rgb(0, 157, 255); */
  background: #F7F8FB;
  border-radius: 16px;
  cursor: pointer;
  color: rgb(0, 157, 255);
  font-size: 24px;
}

.NewLine {
  border-bottom: 1px dashed #ccc;
  margin-bottom: 14px;
}

.headerprogress {
  padding-bottom: 10px;
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #8492A5;
}

.room {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #273443;
}

.actCountName {
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 4px;
  height: 22px;
  line-height: 140%;
  color: #273443;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 16px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 78px !important;
  margin-left: 0;
  background: #FFFFFF !important;
  border: 1px solid #B0BBCB !important;
  border-radius: 16px !important;
  line-height: 25px;
  height: 48px !important;
  width: 100% !important;
  outline: none;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 16px !important;
  background: #F7F8FB !important;
  border: 1px solid #B0BBCB !important;
  border-radius: 16px !important;
}

.actCount {
  width: 76px;
  height: 48px;
  padding: 10px 4px;
  text-align: center;
  margin-right: 8px;
  margin-left: 8px;
  /* border: 1px solid rgb(0, 157, 255); */
  background: #FFFFFF;
  border: 1px solid #E0E6ED;
  border-radius: 16px;
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #273443;
}


.MuiCheckbox-root {
  color: #0094FF !important;

}

.datesflex {
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* color: #273443; */
}

.total {
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #0094FF;
  margin-bottom: 24px;
}

.textBoxArea input {
  /* width: calc(100% - 50px);
  padding: 10px;
  border: 1px solid rgb(0, 157, 255);
  border-radius: 4px;
  font-size: 16px; */
}

.head {
  max-width: 600px;
  height: 300px;
  padding-top: 10px;
  margin-bottom: 30px;
  font-size: 20px;
  background: #F7F8FB;
  border-radius: 16px;
}

.subhead {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #273443;
  padding-top: 10px;
  padding-bottom: 20px;


}

.header h3 {
  margin-bottom: 30px;
}

.Modal {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 480px;
  top: 10%;
  left: 0px;
  text-align: center;
}

.ModalContent {
  width: 800px;
  background-color: white;
  border-radius: 18px;
  display: inline-block;
  min-height: 481px;
}

.mainHeaderBG {
  /* height: 200px; */
  /* background-image: url("../src/asset/frames/01.jpg"); */
  /* opacity: 0.6; */
}

.mainLogo {
  /* background-image: url("../src/asset/frames/logo.svg"); */
  /* background-repeat: no-repeat;
  height: 48px;
  margin-top: -80px;
  position: absolute;
  z-index: 1;
  width: 400px;
  margin-left: 52px; */
}

.subLog {
  background-image: url("../src/asset/frames/logo.svg");
  background-repeat: no-repeat;
  height: 48px;
  z-index: 1;
  max-width: 400px;
}

.headTitle {
  padding: 16px;
  padding-bottom: 24px;
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #273443;
}

.Step {
  cursor: pointer;
}

.Box {
  display: flex;
}

.Box .left {
  width: 100px;
}

.Box .right {
  text-align: left;
  padding: 0px 20px;
  width: calc(100% - 200px);
  margin-top: 40px;
}

.errorValidation {
  color: orangered;
  margin: 0px 10px;
  height: 25px;
  text-align: right;
}

.full {
  text-align: left;
  padding-top: 28px;
  width: 100%;
}

.Trans {
  background-color: black;
  opacity: 0.2;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

h3 {
  font-size: 22px !important;
  line-height: 28px !important;
  margin-bottom: 16px !important;
}

h4 {
  font-size: 18px !important;
  line-height: 24px !important;
  margin-bottom: 16px !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 140% !important;
  color: #273443 !important;
}

.SubTitle {
  margin-top: 16px;
  margin-bottom: 20px !important;
}

.sub {
  margin: 8px 0px 0px 15px;
}

select {
  padding: 10px 16px;
  margin: 6px !important;
  font-size: 16px !important;
  appearance: none;
  border-radius: 3px;
  min-width: 100px;
  border: 1px solid rgb(0, 157, 255);
  background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='rgb(0, 157, 255)'/></g></svg>") no-repeat;
  cursor: pointer;
  background-position: right 14px top 50%;
  border: 1px solid #E0E6ED;
  border-radius: 16px;
}

.mainPage {
  margin: auto;
  padding: 20%;
  text-align: center;
}

.mainPage .mainPageButton {
  padding: 18px 28px;
  background-color: rgb(0, 145, 235);
  color: white;
  cursor: pointer;
  border-radius: 20px;
  font-size: 18px;
}

.selectage {
  border: 1px solid #E0E6ED !important;
  border-radius: 16px !important;
  font-family: HKGrotesk !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 140% !important;
  color: #000000 !important;
  font-size: 16px !important;
  width: 140px !important;
  height: 48px;
  padding: 14px;
}

.ChildAge {
  display: block;
  padding-left: 4px;
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #8492A5;
  margin-left: 18px;
}

.summery {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #8492A5 !important;
}

.summery span {
  color: rgb(0, 145, 235);
}

.hide {
  display: none;
}

/* .show {
  display: block;
  padding: 10px;
  padding-left: 20px;
  position: absolute;
  bottom: 8px;
  width: 100%;
} */
.rs-steps-vertical .rs-steps-item:not(:first-child) {
  margin-top: 4px !important;
}

.rs-steps-vertical .rs-steps-item-tail {
  top: 35px !important;
}

.rs-steps-vertical .rs-steps-item {
  padding-bottom: 24px !important;
}

.rs-steps-item-content {
  text-align: left !important;
}

.Explanation {
  margin-bottom: 20px;
  margin-top: -10px;
  color: rgb(173, 172, 172);
  font-size: 14px;
  font-style: italic;
}

.tripLocation {
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #0094FF;
}

.flex {
  display: flex;
  width: 100%;
}

.marginRight {
  margin: 16px;
}


.displayfromto {
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #273443;
  margin-left: 18px;
  margin-bottom: 7px;
}

.maxArea {
  min-height: 300px;
}

.ChildrenAgeSet {
  display: inline-block;
  /* background-color: red; */
  width: 48%;
  margin-top: 10px;
  margin-right: 4px;
}

.RoomArrangement {
  padding: 5px;
  margin-top: 10px;
}

.roomContainer {
  padding: 18px;
}

.rooms {
  max-width: 400px;
  display: block;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 6px;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  border: 1px solid #E0E6ED;
  border-radius: 24px;
  background: #F7F8FB;
}

.roomHeader {
  display: flex;
  width: 100%;
  /* border-bottom: 1px solid #ddd; */
}

.roomsTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #273443;
}

.roomsTitle svg {
  margin-top: -2px;
}

.roomsTitle div {
  margin-top: 2px;
  padding-left: 6px;
}

.actionBox {
  display: flex;
}

.nme {
  width: 100%;
  padding: 4px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #0094FF;
}

.roomAction {
  padding-top: 6px;
}

.count {
  margin: 12px 12px 0px 15px;

}

.roomCount {
  width: 76px;
  margin: -4px 5px 0px 5px;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #E0E6ED;
  border-radius: 16px;
  text-align: center;
}

.roomBtn {
  font-size: 20px;
  padding: 0px 4px;
  background: #F7F8FB;
  border-radius: 16px;
  margin-top: -4px;
  width: 48px;
  height: 48px;
  color: rgb(0, 145, 235);
  cursor: pointer;
}

.roomsRemove {
  float: right;
  /* background-color: rgb(0, 145, 235); */
  color: white;
  margin-top: -4px;
  font-size: 14px;
  border-radius: 20px;
  padding: 2px 4px;
}

.PlusIconRight {
  margin-left: 67%;
  margin-top: 28px;
  margin-bottom: 52px;
  position: relative;
  font-size: 14px;
  display: flex;
  color: rgb(0, 145, 235);
  cursor: pointer;
}

.flex {
  display: flex;
  margin-top: 9px;
}

.roomsRemove {
  cursor: pointer;
  color: #666;
}

.disabled {
  cursor: not-allowed;
  color: gray;
}

.roomButton {
  font-size: 20px;
}

.roomsRemove:hover {
  color: black;
}

.PlusIconRight span {
  display: inline-block;
  margin-top: 0px;
}

.roomInfo {
  padding: 4px;
  /* padding-left: 40px; */
  margin-top: 8px;
  text-align: center;
  color: rgb(0, 145, 235);
  font-size: 14px;
}

.displayNone {
  display: none !important;
}

.loaderMain {
  /* background-color: red; */
  position: absolute;
  text-align: center;
  margin-left: 40px;
  padding: 80px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.inlineloader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.PopUpElement {
  position: absolute;
  width: 100%;
  left: 0px;
  text-align: center;
  justify-content: center;
  top: 200px;
  z-index: 1234567890;
}

.PopUpElementComp {
  display: inline-block;
  background-color: white;
  /* border: 1px solid #3498db;  */
  position: relative;
  top: 10px;
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  z-index: 1234567890;

}
.closeDisplay{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 20px;
  margin-right: 20px;
}
.closeDisplayIn{
  width:21.58px !important;
  border:1.5px solid #273443 !important;
  border-radius:5px !important;
  
}
.closeDisplayIn .MuiSvgIcon-root {
fill: #273443 !important;
width: 14px !important;
    height: 14px !important;
    margin-top: 5px
;
}

.popUpBG {
  background-color: black;
  opacity: 0.3;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  
}

.okButton {
  height: 40px;
  margin-bottom: 80px;
  width: 100%;
  width: 328px;
  height: 48px;
  margin-right: 36px;
  /* left: 16px; */
  /* top: 500px; */
  background: #0094FF;
  box-shadow: 0px 4px 10px rgb(0 148 255 / 16%);
  border-radius: 16px;
}
.month-ok-button{
  margin-top: 10px;
  height: 40px;
  margin-bottom: 40px;
  /* width: 100%; */
  width: 328px;
  height: 48px;
  margin-right: 23px;
  /* left: 16px; */
  /* top: 500px; */
  background: #0094FF;
  box-shadow: 0px 4px 10px rgb(0 148 255 / 16%);
  border-radius: 16px;
}



.rdrMonthName {
  text-align: left;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 140%;
  color: #273443 !important;
}
.innerDate{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.picker-year {
  display: flex;
  justify-content: center;
  gap: 90px;
  cursor: pointer;
  
}
.main-picker{
  /* background-color: red; */
  max-width: 360px;
}
.showMonth{
  display: flex;
  justify-content: center;
}
.css-1sy1a1q-MuiTypography-root-PrivatePickersMonth-root{
  max-width: 93px !important;
  background-color: #F7F8FB !important;
  margin: 4px !important;
  height: 80px !important;
  border-radius: 8px !important;
}
.css-15fehp8-MuiTypography-root-PrivatePickersMonth-root.Mui-selected{
  max-width: 93px !important;
  margin: 4px !important;
  height: 80px !important;
  border-radius: 8px !important;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 157, 255, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 3px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(0, 157, 255);
  border: 1px solid rgb(0, 157, 255);
}

