@font-face {
  font-family: HKGrotesk;
  src: url(asset/fonts/hk-grotesk/HKGrotesk-Medium.otf);
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiOutlinedInput-root {
  background: #FFFFFF !important;
  /* border: 1px solid #B0BBCB !important; */
  border-radius: 16px !important;
  padding: 5px;
}

.MuiInputLabel-outlined.MuiInputLabel-marginDense {
  /* transform: translate(14px, 12px) scale(1); */
  /* padding: 5px !important; */
  font-family: HKGrotesk;
  font-style: normal;
  /* font-weight: 500; */
  /* font-size: 16px; */
  line-height: 140%;
  color: #B0BBCB;
}